import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  QueryParams,
  useLazyGetVenuesQuery,
  Venue,
} from 'manage-tritag/services/api/endpoints/venues'
import Spinner from 'manage-tritag/components/loading/spinner'

interface HeadCell {
  disablePadding: boolean
  id: keyof Venue
  label: string
  numeric: boolean
}

const VenuePage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetVenuesQuery()

  const { isLoading, data: venues } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'association',
      numeric: false,
      disablePadding: false,
      label: 'Association',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },
  ]
  const bodyCells = ['ttr_id', 'name', 'association', 'address']
  return (
    <Layout label1="Venues" url="/venues">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        venues && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Venues"
              uniqueIdentifier="ttr_id"
              rows={venues.map((venue, index) => ({
                ...venue,
                id: index,
                association: venue.association
                  ? venue.association.name
                  : 'None',
                address: venue.address.city,
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(VenuePage)
