import { api } from '..'
import { Association } from './associations'

export interface Address {
  formatted: string
  line_1: string
  line_2: string
  city: string
  state: string
  postcode: string
  country: string
  location: {
    lat: number
    lng: number
  }
}

export interface Venue {
  _id: string
  ttr_id: string
  name: string
  association: Association
  address: Address
  abn: number
  fieldMap: string
  fields: number
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type VenuesResponse = Venue[]

export const venuesApi = api.injectEndpoints({
  endpoints: build => ({
    getVenues: build.query<VenuesResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'venues/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['venues'],
    }),
    getVenue: build.query<Venue, string>({
      query: id => `venues/${id}`,
      providesTags: ['venue'],
    }),
    addVenue: build.mutation({
      query: body => ({
        url: 'venues',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['venues'],
    }),
    updateVenue: build.mutation({
      query: body => {
        const formData = JSON.parse(body.get('formData'))
        return {
          url: `venues/${formData.id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['venues', 'venue'],
    }),
    deleteVenue: build.mutation({
      query: id => ({
        url: `venues/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['venues'],
    }),
  }),
})

export const {
  useGetVenuesQuery,
  useGetVenueQuery,
  useAddVenueMutation,
  useLazyGetVenuesQuery,
  useUpdateVenueMutation,
  useDeleteVenueMutation,
} = venuesApi
